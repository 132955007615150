import Vue from 'vue'
import money from 'v-money'

Vue.use(money, { precision: 2 })

export default {
    init() {
        new Vue({
            el: '#donation-form',
            data: () => ({
                donation: 0,
            }),
            template: `
                <div id="donation-form">
                    <form action="https://www.uif.uillinois.edu/gifts/PaymentPageTransfer.aspx" method="POST" target="_blank">
                    <input name="CHANGE_DATA_URL" type="hidden" value="http://www.educationjustice.net/">
                    <input name="COMPLETED_URL" type="hidden" value="http://www.educationjustice.net/">
                    <input name="FUND1" type="hidden" value="11340338/Education Justice Project">
                    <input name="DEPARTMENT" type="hidden" value="Education Justice Project">
                    <input name="TRACKING_CODE" type="hidden" value="10240">
                    <div class="d-flex">
                        <h2 class="h3 no-line">Gift Amount: </h2>
                      <label>
                        <span class="sr-only">Amount</span>
                        <money v-model="donation" decimal="." thousands="," prefix="$" :precision="2" />
                      </label>
                        <input type="hidden" name="GIFT_AMOUNT1" :value="donation" />
                        <input class="btn btn-blue" type="submit" value="Continue" />
                    </div>
                    </form>
                </div>
            `,
        })
    },
  };
